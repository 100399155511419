import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Page</h1>
      <p>Learn more about us here.</p>
    </div>
  );
};

export default About;
